import React from 'react'

import logo from '../../images/logo-text.svg'

import './index.scss'

export default () => (
  <div className="header">
    <div className="header_container">
      <div className="header_logo">
        <img src={ logo } alt="Unicorn" />
      </div>
      <div className="header_contact">
        <a href="mailto:hello@unicorntechhouse.com">
          Say Hello
        </a>
      </div>
    </div>
  </div>
)
