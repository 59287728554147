import React from 'react'

import geometric from '../../images/geometric1.svg'

import './index.scss'

export default () => (
  <div className="whoweare">
    <div className="whoweare_container">
      <div className="about-us">
        <p className="intro">
          We’re a software house located in Brazil and Portugal. We have a relentless passion for creating clear, thoughtful products that can redefine our day to day relationship with technology.
        </p>
        <p>
          We carefully approaching each problem as a unique challenge, helping companies to deal with major problems, using technology to drive and test new ideas, understanding the challenges involved in building great software.
        </p>
      </div>

      <div className="geometric1">
        <img src={ geometric } alt="Geometric Form" />
      </div>
      <div className="geometric2">
        <img src={ geometric } alt="Geometric Form" />
      </div>
    </div>
  </div>
)
