import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Home from './screens/Home'
import NotFound from './screens/NotFound'

export default () => (
  <Router basename="/">
    <Switch>
      <Route path="/" exact={true} component={ Home } />
      <Route path="*" component={ NotFound } />
    </Switch>
  </Router>
)
