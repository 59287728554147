import React, { useEffect } from 'react'

import Header from '../../components/Header'
import Hero from '../../components/Hero'
import WhoWeAre from '../../components/WhoWeAre'
import WhatWeDo from '../../components/WhatWeDo'
import Team from '../../components/Team'
import Contact from '../../components/Contact'

export default function Home() {
  useEffect(() => {
    const colors = [
      ['#6a2f84', '#d51d58'],
      ['#029478', '#96bf29'],
      ['#ea664d', '#fbc00c']
    ]
    const color = Math.floor(Math.random() * colors.length)

    document.body.setAttribute(
      'style',
      `background-image: linear-gradient(to bottom right, ${ colors[color][0] }, ${ colors[color][1] })`
    )
  }, [])

  return (
    <>
      <Header />
      <Hero />
      <WhoWeAre />
      <WhatWeDo />
      {/* <Team /> */}
      <Contact />
    </>
  )
}
