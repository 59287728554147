import React from 'react'

import unicorn from '../../images/logo-unicorn.svg'

import './index.scss'

export default () => (
  <div className="hero">
    <div className="hero_container">
      <div className="unicorn">
        <img src={ unicorn } alt="Unicorn" />
      </div>
      <div className="description">
        <h1>
          Code<br />
          Design<br />
          Caffeine
        </h1>
      </div>
    </div>
  </div>
)
