import React from 'react'

import geometric from '../../images/geometric3.svg'

import './index.scss'

export default () => (
  <div className="whatwedo">
    <div className="whatwedo_container">
      <div className="wedo">
        <h2 className="title">What We Do</h2>
        <p className="skills">
          Planning / Art Direction / Brand & Identity / Digital Product Design / Interface Design / User Experience / Marketing / Technical Strategy / Web Design / Web Development / Mobile Apps / SEO
        </p>
        <p>
          We’re ready to collaborate, from beginning to end, in creating products that generate positive impact for your business.
        </p>
      </div>

      <div className="geometric1">
        <img src={ geometric } alt="Geometric Form" />
      </div>
      <div className="geometric2">
        <img src={ geometric } alt="Geometric Form" />
      </div>
    </div>
  </div>
)
