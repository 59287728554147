import React from 'react'

import unicorn from '../../images/logo-unicorn-white.svg'

import './index.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fab)

export default () => (
  <div className="contact">
    <div className="contact_container">
      <div className="description">
        <h2 className="title">
          Let's Talk
        </h2>
        <p className="contact_text">
          We’re always looking to collaborate with amazing people, so feel free to send us an email to tell us more about your idea.
          <br /><br />
          <a href="mailto">hello@unicorntechhouse.com</a>
        </p>
      </div>
      <div className="social">
        <a href="https://facebook.com/weareuth" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={['fab', 'facebook-square']} />
        </a>
        <a href="https://instagram.com/weareuth" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={['fab', 'instagram']} />
        </a>
        {/* <a href="https://twitter.com/weareuth" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={['fab', 'twitter']} />
        </a> */}
        <a href="https://linkedin.com/company/weareuth" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={['fab', 'linkedin']} />
        </a>
      </div>

      <div className="unicorn">
        <img src={ unicorn } alt="Unicorn" />
      </div>
    </div>
  </div>
)
